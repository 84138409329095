<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Récompenses obtenues" size="lg">
        <div v-show="false">
            Pour obtenir une récompense cachée, vous devrez visiter cette page : {{ apiPath('secret_item_hard') }}.
            Attention toutefois, l'entrée ne sera pas facile.
        </div>
        <div class="text-center">
            <collected-item :item-type="item.type"
                            :obtention="item.obtention"
                            v-for="item in collectedItems"
                            :key="'collectable_'+item.id"/>
        </div>
        <div class="text-center mt-3">
            <b-button @click="hideModal">Ok</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin       from '@/mixin/modalMixin';
    import {apiPath}        from '@/util/http';
    import {naturalSortAsc} from '@/util/sort';
    import {momentDates}    from '@/util/collectableItem';

    import {mapState} from 'vuex';

    const CollectedItem = () => import('@/components/collectable_item/CollectedItem');

    export default {
        name: "DisplayCollectedItemsModal",
        components: {CollectedItem},
        mixins: [modalMixin],
        data: () => ({
            modalRef: 'displayCollectedItems'
        }),
        computed: {
            ...mapState({user: 'user'}),
            collectedItems() {
                return momentDates(this.user.utilisateur.collectableItems.sort((a, b) => naturalSortAsc(a.type, b.type)));
            }
        },
        methods: {apiPath}
    }
</script>
